

const StarRating = ({ rating, width, height }) => {
    // Calculate the percentage based on the rating
    const percentage = (rating / 5) * 100;

    return (
 
            <div className="stars-outer" style={{width: width, height: width*0.17}}>
                <div className="stars-inner" style={{ width: `${percentage}%` }}></div>
            </div>
  
    );
};

export default StarRating;