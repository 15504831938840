import React, { useState, useEffect, useMemo } from 'react';
import './App.css';
import Dashboard from './Dashboard';
import Login from './Login';

function App() {

  const token = localStorage.getItem("token");
  
  return (
    token ?
    <Dashboard />
    :
    <Login />
  );
}

export default App;
