import React, { useState, useEffect, useMemo } from 'react';
import './App.css';
import logo from './img/logo.svg';

function Login() {
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
      e.preventDefault();
      // Handle login logic here
      console.log('Username:', username);
      console.log('Password:', password);
  };

  function login() {
    if(username == "" || password == "") {
      alert("Bitte gib deinen Benutzernamen und Passwort ein.");
      return;
    }
    localStorage.setItem("token", `${username}|${password}`);
    window.location.reload();
  }

  return (
    <div className='page-login'>
        <div className="login-container">
            <img src={logo} className='login-logo' />
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password">Passwort</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className='login-button' onClick={() => login()}>Anmelden</div>
            </form>
        </div>
      </div>
  );
}

export default Login;
