import { formatNumber, formatDate } from './functions.js';
import StarRating from './StarRating.js';

const FeedbackCard = ({rating, feedback, slug, id, title, timestamp}) => {

    return (

        <div className='feedbackitem'>
            <span className='feedbackitem-rating'>{parseFloat(rating).toFixed(0)} Sterne</span><StarRating rating={rating} width={60} />
            {feedback && <p className='feedbackitem-feedback'>"{feedback}"</p>}
            <div className='feedbackitem-titlebox'>
                <img className='feedbackitem-trailimg' src={`https://static.wunderweg.com/clients/${slug}/${id}/badge.png`} />
                <p className='feedbackitem-title'>{title}</p>
            </div>

            <span className='feedbackitem-date'>{formatDate(timestamp)}</span>
        </div>

    );
};

export default FeedbackCard;