import React, { useState, useEffect, useMemo } from 'react';
import StarRating from './StarRating.js';

import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';

import loadingimg from '../img/loading.svg';

import { formatNumber, formatDate } from './functions.js';

import FeedbackCard from './FeedbackCard.js';

const TrailModal = ({trail, onClose, ...props }) => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  // Ensure hooks are called unconditionally
  useEffect(() => {
    if (!trail) return;


    setLoading(true);
    setData({});

    // Define an async function to fetch data
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}traildetail.php?trailid=${trail.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Add the Authorization header
            'Content-Type': 'application/json'  // Ensure the content type is set
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        console.log("new data: ");
        console.log(result);
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    // Call the async function
    fetchData();
  }, [trail]);


  const totalOpened = useMemo(() => {
    return data?.stations?.reduce((total, item) => {
      return total + parseInt(item.opened, 10);
    }, 0);
  }, [data]);

  const maxOpened = useMemo(() => {
    return data?.stations?.reduce((max, item) => {
      const openedValue = parseInt(item.opened, 10);
      return openedValue > max ? openedValue : max;
    }, 0);
  }, [data]);

  const maxRating = useMemo(() => {
    return data?.ratingOverview?.reduce((max, item) => {
      const openedValue = parseInt(item, 10);
      return openedValue > max ? openedValue : max;
    }, 0);
  }, [data]);

  const stationCountData = useMemo(() => ({
    options: {
      dataLabels: {
        enabled: false
      },
      chart: {
        id: 'station-count-bar-chart'
      },
      xaxis: {
        categories: data?.stationcount?.map(item => item.station_count),
        title: {
          text: 'Wie viele Stationen wurden angesehen'
        }
      },
      yaxis: {
        title: {
          text: 'Anzahl an Besuchern'
        }
      },
    },
    series: [
      {
        name: 'Besucher',
        data: data?.stationcount?.map(item => parseInt(item.user_count, 10))
      }
    ]
  }), [data]);

  if (!trail) {
    return null; // Render nothing if trail is null
  }

  let newstarted = parseInt(trail.done) > parseInt(trail.started) ? trail.done : trail.started;

  return (
    <div className={`modal-overlay ${trail == null ? "modalclosed" : ""}`}>
      <div onClick={onClose} className='modalbackground'></div>
      <div className="modal-content">
        <button onClick={onClose} className="close-button">Schließen X</button>
        <h2 className='trailheadline'>{trail.title}</h2>
        <div className='trailboxdatagrid'>
          <div className='trailboxdatabox'>
            <p className='trailboxdata-val'>{formatNumber(newstarted)}</p>
            <p className='trailboxdata-label'>Gestartet</p>
          </div>

          <div className='trailboxdatabox'>
            <p className='trailboxdata-val'>{formatNumber(trail.done)}</p>
            <p className='trailboxdata-label'>Erledigt</p>
          </div>

          <div className='trailboxdatabox'>
            <p className='trailboxdata-val'>{(trail.done / newstarted * 100).toFixed(0)}<span className='val-smalltext'>%</span></p>
            <p className='trailboxdata-label'>Success rate</p>
          </div>

          <div className='trailboxdatabox'>
            <p className='trailboxdata-val'>{trail['rating-avg'] ? parseFloat(trail['rating-avg']).toFixed(1) : <span style={{ opacity: 0.4 }}>–</span>}</p>
            <p className='trailboxdata-label'>Rating ({trail['rating-count']})</p>
            {trail['rating-avg'] && <StarRating rating={parseFloat(trail['rating-avg'])} width={60} />}
          </div>
        </div>

        {
          loading
            ?
            <div className='loadingcont'><img src={loadingimg} /></div>
            :
            <div className='traildetaildatacont'>
              <h2 style={{ marginTop: 56 }}>{data?.stations?.length} Stationen</h2>
              <p className='tiptext'>💡 Hier sieht man wie oft die Stationen jeweils angesehen wurden. Die erste Station wird typischerweise am häufigsten geöffnet, danach mindert sich das Interesse.</p>
              {
                data?.stations?.map((obj, index) =>
                  <div className='stationbox'>
                    <div className='stationboxtext'>
                      <div className='stationboxtitle'>
                        <div className='stationboximage' style={{ backgroundImage: `url("https://static.wunderweg.com/clients/${data.region.slug}/${trail.id}/${obj.id}/image.png")` }}></div>
                        <h3>{obj.title}</h3>
                      </div>
                      {obj?.opened}
                    </div>
                    <div className='stationbox-bar'>
                      <div className='stationbox-bar-inner' style={{ width: obj.opened / maxOpened * 100 + "%" }}></div>
                    </div>
                  </div>
                )
              }

              <h2 style={{ marginTop: 56 }}>Abbrecher</h2>
              <p className='tiptext'>💡 Dies sind alle Besucher, die den Weg gestartet, aber nicht beendet haben (also nicht das Abzeichen eingesammelt). Die Zahl unten gibt an, wie viele der {data?.stations?.length} Stationen angesehen wurden bis zum Abbruch.</p>
              <Chart options={stationCountData.options} series={stationCountData.series} type="bar" width="100%" height={500} />

              <h2 className='sectionheadline'>Feedback</h2>
              <div className='ratingdetailbox'>
                <div>
                  <p className='trailboxdata-val'>{trail['rating-avg'] ? parseFloat(trail['rating-avg']).toFixed(1) : <span style={{ opacity: 0.4 }}>–</span>}</p>
                  <p className='trailboxdata-label'>Rating ({trail['rating-count']})</p>
                  {trail['rating-avg'] && <StarRating rating={parseFloat(trail['rating-avg'])} width={100} />}
                </div>
                <div>
                  {
                    data?.ratingOverview?.map((obj, index) =>
                      <div className='ratingdetailbarcontainer'>
                        <p>{5 - index}</p>
                        <div className='ratingdetail-bar'>
                          <div className='ratingdetail-bar-inner' style={{ width: obj / maxRating * 100 + "%" }}></div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className='feedbackcontainer'>
                {
                  data?.feedback?.map((obj, index) => (
                    <FeedbackCard key={index} {...obj} slug={data.region.slug} />
                  ))
                }
              </div>
              <div className='secondarybtn' onClick={() => props.closeallandopenFeedback()}>Alle anzeigen</div>
            </div>
        }




      </div>
    </div>

  );
};

export default TrailModal;