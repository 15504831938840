export function formatNumber(num) {
    if (num < 1000) return num.toString();
    const units = ["k", "M", "B", "T"];
    const unitIndex = Math.floor(Math.log10(num) / 3) - 1;
    const unitValue = Math.pow(1000, unitIndex + 1);
    const formattedNum = (num / unitValue).toFixed(1).replace(/\.0$/, ''); // Remove .0 if it's a whole number
    return formattedNum + units[unitIndex];
  }
   
  export const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };
  
  export const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.getDate() === yesterday.getDate() &&
           date.getMonth() === yesterday.getMonth() &&
           date.getFullYear() === yesterday.getFullYear();
  };
  
  export const formatDate = (timestamp) => {
    const date = new Date(timestamp);
  
    if (isToday(date)) {
        return 'Heute';
    } else if (isYesterday(date)) {
        return 'Gestern';
    } else {
        const options = { day: 'numeric', month: 'long' };
        return date.toLocaleDateString('de-DE', options);
    }
  };