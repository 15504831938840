import React, { useState, useEffect, useMemo } from 'react';
import StarRating from './StarRating.js';

import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';

import loadingimg from '../img/loading.svg';

import { formatNumber, formatDate } from './functions.js';

import FeedbackCard from './FeedbackCard.js';

const FeedbackModal = ({ opened, onClose }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [rating, setRating] = useState(null);  // Selected rating filter
  const [trail, setTrail] = useState(null);    // Selected trail filter
  const [onlyText, setOnlyText] = useState(false); // Only with text filter
  const [sortOption, setSortOption] = useState('newest'); // Sorting filter

  // Fetch data when the modal is opened
  useEffect(() => {
    if (!opened) return;

    setLoading(true);
    setData({});

    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}getFeedback.php`, {
          headers: {
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'  
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [opened]);

  // Unique titles for trail filter
  const uniqueTitles = useMemo(() => {
    const titlesSet = new Set();
    data?.feedback?.forEach(item => titlesSet.add(item.title));
    return Array.from(titlesSet);
  }, [data]);

  // Filtered data based on selected filters
  const filteredData = useMemo(() => {
    let filtered = data?.feedback || [];

    // Filter by rating
    if (rating !== null) {
      filtered = filtered.filter(item => parseInt(item.rating) === rating);
    }

    // Filter by trail title
    if (trail) {
      filtered = filtered.filter(item => item.title === trail);
    }

    // Filter by only text
    if (onlyText) {
      filtered = filtered.filter(item => item.feedback.trim() !== '');
    }

    // Sort the filtered data
    filtered.sort((a, b) => {
      switch (sortOption) {
        case 'newest':
          return new Date(b.timestamp) - new Date(a.timestamp);
        case 'oldest':
          return new Date(a.timestamp) - new Date(b.timestamp);
        case 'best':
          return b.rating - a.rating;
        case 'worst':
          return a.rating - b.rating;
        default:
          return 0;
      }
    });

    return filtered;
  }, [data, rating, trail, onlyText, sortOption]);

  return (
    <div className={`modal-overlay ${!opened ? "modalclosed" : ""}`}>
      <div onClick={onClose} className='modalbackground'></div>
      <div className="modal-content">
        <button onClick={onClose} className="close-button">Schließen X</button>
        <h2 className='trailheadline'>Feedback Detail</h2>

        {loading ? (
          <div className='loadingcont'><img src={loadingimg} alt="Loading" /></div>
        ) : (
          <div className='traildetaildatacont'>
            <div className='filtercontainer'>
              <div className='filterbox'>
                <p>Rating:</p>
                <div className='filterrow'>
                  <div className={`filterbubble ${rating == null && "filterbubbleactive"}`} onClick={() => setRating(null)}>Alle</div>
                  {[...Array(5)].map((_, index) => (
                    <div key={index} className={`filterbubble ${rating == (5 - index) && "filterbubbleactive"}`} onClick={() => setRating(5 - index)}>
                      {5 - index} <span style={{ fontSize: "80%" }}>★</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className='filterbox'>
                <p>Weg:</p>
                <div className='filterrow'>
                  <div className={`filterbubble ${trail == null && "filterbubbleactive"}`} onClick={() => setTrail(null)}>Alle</div>
                  {uniqueTitles.map((title, index) => (
                    <div key={index} className={`filterbubble ${trail == title && "filterbubbleactive"}`} onClick={() => setTrail(title)}>
                      {title}
                    </div>
                  ))}
                </div>
              </div>

              <div className='filterbox'>
                <p>Text:</p>
                <div className='filterrow'>
                  <div className={`filterbubble ${!onlyText && "filterbubbleactive"}`} onClick={() => setOnlyText(false)}>Alle</div>
                  <div className={`filterbubble ${onlyText && "filterbubbleactive"}`} onClick={() => setOnlyText(true)}>Nur mit Text</div>
                </div>
              </div>

              <div className='filterbox'>
                <p>Sortierung:</p>
                <div className='filterrow'>
                  <div className={`filterbubble ${sortOption == "newest" && "filterbubbleactive"}`} onClick={() => setSortOption('newest')}>Neueste</div>
                  <div className={`filterbubble ${sortOption == "oldest" && "filterbubbleactive"}`} onClick={() => setSortOption('oldest')}>Älteste</div>
                  <div className={`filterbubble ${sortOption == "best" && "filterbubbleactive"}`} onClick={() => setSortOption('best')}>Beste</div>
                  <div className={`filterbubble ${sortOption == "worst" && "filterbubbleactive"}`} onClick={() => setSortOption('worst')}>Schlechteste</div>
                </div>
              </div>
            </div>

            <p style={{fontSize: 20, marginBottom: 16}}>{filteredData.length} {filteredData.length == 1 ? "Eintrag" :  "Einträge"}</p>
            {filteredData.map((obj, index) => (
              <FeedbackCard key={index} {...obj} slug={data.region.slug} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackModal;
